.bg-blue-codegym {
  background-color: #272781 !important;
  color: white;
}
.text-orange-codegym {
  color: #f05b25 !important;
}
.color-white {
  color: white !important;
}
